.authentication_page {
  height: 100%;
  display: flex;
  flex-direction: column;

  & > div {
    margin: 125px auto;
    text-align: center;
    display: flex;
    flex-direction: column;
  }
}
