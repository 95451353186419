.app_header {
  margin: 0;
  height: 26px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  background-color: rgb(63, 86, 102);

  & > div {
    margin: 0;
    height: 100%;

    & > h3 {
      margin: 2px 10px;
      font-size: 18px;
    }

    & > button {
      margin: 4px 4px;
    }
  }
}
