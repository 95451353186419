.builder_panel {
  margin: 0;
  display: flex;
  overflow: hidden;

  & > div {
    margin: 5px;
    border: 2px solid black;

    .selected {
      border: 1px solid black;
    }
  }
}
