.auth_form {
  & > h3 {
    margin-top: 6px;
    margin-bottom: 6px;
  }

  & > .auth_msg {
    color: red;
    height: 15px;
    margin-top: 0;
    margin-bottom: 9px;
    font-size: 12px;
  }

  & > form {
    text-align: left;
    margin: auto;
    display: inline-block;
    width: 150px;

    .auth_form__input {
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    .auth_form__button {
      text-align: center;

      & > button {
        display: inline-block;
        width: 120px;
        height: 35px;
      }
    }
  }

  & > .auth_form__link {
    width: 100%;
    margin-top: 15px;
    font-size: 12px;
  }
}
